.text-editor {
  /*
    Styles for remove
   */
  /* Set content font-families */
}
.text-editor .ql-editor {
  max-height: 300px;
  overflow: auto;
}
.text-editor .ql-toolbar .ql-picker {
  cursor: pointer;
}
.text-editor .ql-toolbar .ql-fill {
  cursor: pointer;
}
.text-editor .ql-toolbar .ql-stroke {
  cursor: pointer;
}
.text-editor .ql-editor ul[data-checked="false"] > li::before {
  content: url("../../../../assets/checkbox-off.svg");
  font-size: 1.3rem;
  position: relative;
  bottom: -8px;
}
.text-editor .ql-editor ul[data-checked="true"] li::before {
  content: url("../../../../assets/checkbox-on.svg");
  font-size: 1.3rem;
  position: relative;
  bottom: -8px;
}
.text-editor .ql-container {
  font-size: 16px;
}
.text-editor .ql-snow .ql-picker.ql-size {
  width: 75px;
}
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  content: '15px';
  font-size: 15px !important;
}
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
  font-size: 16px !important;
}
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
  content: '17px';
  font-size: 17px !important;
}
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
  font-size: 18px !important;
}
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px';
  font-size: 20px !important;
}
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.text-editor .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}
.text-editor .ql-picker.ql-font .ql-picker-item {
  font-size: 0;
}
.text-editor .ql-picker.ql-font .ql-picker-item:before {
  content: attr(data-value) !important;
  font-size: 14px;
}
.text-editor .ql-picker.ql-font .ql-active:before {
  content: attr(data-value) !important;
  font-size: 14px;
}
.text-editor .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.text-editor .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}
.text-editor .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.text-editor .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}
.text-editor .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.text-editor .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}
.text-editor .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.text-editor .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}
.text-editor .ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.text-editor .ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  content: "Rubik" !important;
}
.text-editor .ql-font-Roboto {
  font-family: "Roboto";
}
.text-editor .ql-font-Raleway {
  font-family: "Raleway";
}
.text-editor .ql-font-Montserrat {
  font-family: "Montserrat";
}
.text-editor .ql-font-Lato {
  font-family: "Lato";
}
.text-editor .ql-font-Rubik {
  font-family: "Rubik";
}
/*.ql-toolbar .ql-stroke {
  fill: none;
  stroke: black;
}

.ql-toolbar .ql-fill {
  fill: #fff;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #fff;
}*/
#Layer_1 {
  height: 17px;
}
#Layer_1:hover {
  fill: #1890ff;
  height: 17px;
}
