.menu {
  border-right: 0;
  background: #2fd1f7;
  position: sticky;
  top: 64px;
  max-height: 100%;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 5px;
}
@media (max-width: 650px) {
  .menu {
    padding-left: 2px;
    padding-right: 2px;
  }
}
.menu.close {
  visibility: hidden;
}
.ant-layout-sider {
  background: #2fd1f7;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: #eb692f;
}
.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
  background: linear-gradient(to left, #178199cf, #138fabcf);
  border-radius: 3px;
  -webkit-box-shadow: 3px 11px 11px -5px rgba(253, 92, 45, 0.41);
  -moz-box-shadow: 3px 11px 11px -5px rgba(253, 92, 45, 0.41);
  box-shadow: 3px 11px 11px -5px rgba(253, 92, 45, 0.41);
}
.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected a {
  color: white;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: black;
}
.ant-menu-item:hover {
  background: #10657844;
  /*  -webkit-box-shadow: 11px 9px 11px -5px rgba(253, 92, 45, 0.41);
  -moz-box-shadow: 11px 9px 11px -5px rgba(253, 92, 45, 0.41);
  box-shadow: 11px 9px 11px -5px rgba(253, 92, 45, 0.41);*/
  border-radius: 3px;
}
.ant-menu-item:hover .side_icon {
  color: white;
}
.ant-menu-item:hover a {
  color: white;
}
.ant-menu-item a {
  color: black;
  font-weight: bold;
}
.site-layout {
  border-right: 1px lightgray solid;
}
.site-layout .ant-menu-item {
  margin-bottom: 9px!important;
}
@media (max-width: 650px) {
  .site-layout .ant-menu-item {
    padding-left: 14px!important;
  }
}
.ant-menu-item.ant-menu-item-only-child {
  margin: 0;
}
.side_icon {
  font-size: 18px!important;
}
