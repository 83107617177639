.consultant_guidance {
  margin-bottom: 30px;
}
.consultant_guidance .ant-table-content {
  max-height: 550px;
  overflow: auto;
}
.consultant_guidance .member_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.consultant_guidance th.ant-table-cell.teamMembers_column,
.consultant_guidance th.ant-table-cell.guidanceArea_column {
  text-align: center!important;
}
.consultant_guidance .teamMembers_column {
  width: 250px;
}
@media (max-width: 1200px) {
  .consultant_guidance .guidanceArea_column {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .consultant_guidance .guidanceArea_column {
    width: 300px;
  }
}
.consultant_guidance .level_column {
  width: 100px;
}
.consultant_guidance .done_column {
  width: 90px;
}
.consultant_guidance .sentiment_column {
  width: 175px;
}
.consultant_guidance .actions_column {
  width: 95px;
}
.consultant_guidance .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}
.consultant_guidance .config {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
