.script_section_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.script__viewer-inner-content {
  display: flex;
  flex: 1 0 auto;
}
.main_row {
  width: 100%;
}
.structure_main--mixin {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.script__viewer {
  background: white;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.script__viewer .navigation_section {
  display: block;
  width: 100%;
  justify-content: center;
}
.script__viewer .navigation_section .control_section {
  padding: 10px;
}
.script__viewer .navigation_section .control_section button {
  width: 180px;
  margin: 0 5px 0 5px;
}
.script__viewer .navigation_section .control_section button:hover {
  transform: scale(1.01);
}
@media (max-width: 450px) {
  .script__viewer .navigation_section .control_section {
    display: flex;
  }
}
.script__viewer .navigation_section .end_call_row {
  border-top: 2px solid lightgrey;
  padding: 14px;
}
.script__viewer .navigation_section .end_call_row button {
  width: 180px;
}
.script__viewer .navigation_section .end_call_row button:hover {
  transform: scale(1.01);
}
.script__viewer .breath_section {
  width: 100%;
  padding: 12px 0 7px 0;
  border-bottom: 2px solid lightgrey;
}
.script__viewer .title_section {
  text-align: center;
}
.script__viewer .title_section .stage_name {
  margin: 0;
  font-weight: bold;
  font-size: 33px;
}
.script__viewer .title_section .objection_name {
  margin: 0;
  font-weight: bold;
  font-size: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 900px) {
  .script__viewer .title_section h1 {
    font-size: 25px;
  }
}
.script__viewer .script_section {
  height: 100%;
  order: 0;
}
.script__viewer .script_section ::selection {
  background: lightyellow;
  text-shadow: none;
  color: black;
}
.script__viewer .script_section .selected {
  background: lightyellow;
}
.script__viewer .script_section .navigation_section {
  display: flex;
  justify-content: center;
}
.script__viewer .script_section .navigation_section button {
  margin-bottom: 10px;
  font-size: 12px;
}
.script__viewer .script_section .navigation_section button:first-child {
  margin-right: 10px;
}
.script__viewer .objection_section {
  background: #e8e8e7;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px solid #afafaf;
  border-left: 2px solid #afafaf;
  display: flex;
  justify-content: space-beetwen;
}
.script__viewer .objection_section button {
  margin-bottom: 10px;
}
.script__viewer .objection_section button:hover {
  transform: scale(1.01);
}
.script__viewer .objection_section button {
  margin-bottom: 10px;
  font-size: 12px;
}
.script__viewer .objection_section button:first-child {
  margin-right: 10px;
}
@media (max-width: 950px) {
  .script__viewer .objection_section {
    padding-top: 10px;
  }
}
.script__viewer .quiz_section {
  background: #e8e8e7;
  text-align: center;
  height: 100%;
  border-left: 2px solid #afafaf;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
}
.script__viewer .quiz_section button {
  margin-bottom: 10px;
  font-size: 12px;
}
.script__viewer .quiz_section button:hover {
  transform: scale(1.01);
}
@media (max-width: 950px) {
  .script__viewer .quiz_section {
    padding-top: 10px;
  }
}
.reason__viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.reason__viewer .reason__section {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.reason__viewer .reason__section .optionalAnswerConfig {
  margin-bottom: 12px;
}
.reason__viewer .reason__section .optionalAnswer {
  min-width: 400px;
}
.reason__viewer .reason__section .optionalAnswer .textarea {
  width: 100%;
}
@media (max-width: 450px) {
  .reason__viewer .reason__section .optionalAnswer {
    min-width: 300px;
  }
}
.reason__viewer .reason__section button {
  min-width: 250px;
  margin-bottom: 10px;
}
.remove_highlight_icon {
  color: red;
}
.remove_highlight_icon:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.15);
}
.highlightHint {
  display: block;
  font-weight: bold;
}
.clear_answer {
  display: none;
}
.clear_answer.visible {
  display: inline-block;
}
.endCallQuestionModal {
  padding: 10px 0 10px;
  height: 100%;
}
.endCallQuestionModal .ant-modal-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.endCallQuestionModal .ant-modal-header {
  position: absolute;
  left: 0;
  right: 0;
}
.endCallQuestionModal .ant-modal-close-x {
  display: none;
}
.endCallQuestionModal .ant-modal-body {
  margin-top: 50px;
}
.endCallQuestionModal .ant-modal-footer {
  width: 100%;
  text-align: center;
}
.test {
  display: flex;
  margin-bottom: 15px;
}
.test .question {
  margin-right: 10px;
}
.ant-input.question.ant-input-disabled {
  background: antiquewhite;
  color: black;
}
.call_stage_assign_select {
  margin-bottom: 50px;
  width: 50%;
}
.endCallQuestionModal button {
  border-radius: 20px;
  min-width: 120px;
}
.endCallModal .ant-modal-body {
  display: flex;
  gap: 10px;
}
#yes_purchase_form {
  width: 100%;
}
#yes_purchase_form .ant-form-item-label {
  text-align: left;
}
.choose_item_modal .user_btn_group {
  max-width: 100%;
}
.end-call-modal .btn {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.end-call-modal .btn_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
