.meetings-page {
  padding-bottom: 25px;
}
.meetings-page .title {
  margin: 0;
}
.meetings-page .meetingConfigPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
}
.meetings-page .action_link {
  font-weight: bold;
  color: black;
}
.meetings-page .action_link:hover {
  color: #1890ff;
  cursor: pointer;
  transform: scale(1.1);
}
.meetings-page .meeting-table .member_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.meetings-page .meeting-table th.ant-table-cell.completedGuidance {
  text-align: center!important;
}
.meetings-page .meeting-table th.ant-table-cell.teamMembers_column {
  text-align: center!important;
}
@media (max-width: 1100px) {
  .meetings-page .meeting-table .completedGuidance {
    width: 500px;
    min-width: 500px;
  }
}
@media (max-width: 450px) {
  .meetings-page .meeting-table .completedGuidance {
    width: 300px;
    min-width: 300px;
  }
}
.meetings-page .dropdown-text p {
  margin: 0;
  overflow-wrap: break-word;
}
