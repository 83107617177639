.statsInfo-page {
  text-align: center;
  background: #2fd1f7;
  height: 100%;
  position: relative;
  padding-top: 10px;
}
.statsInfo-page .title {
  color: white;
}
.statsInfo-page .container {
  max-width: 700px;
  overflow: auto;
}
@media (max-width: 450px) {
  .statsInfo-page .ant-table-content {
    font-size: 9px;
  }
}
.statsInfo-page thead[class*="ant-table-thead"] th {
  background-color: #f28a3c;
  font-weight: bold;
  text-align: center;
}
.statsInfo-page .ant-table-tbody {
  font-weight: bold;
}
.statsInfo-page .table_stats {
  padding-bottom: 125px;
  overflow: auto;
}
