.loader_modal .ant-modal-footer {
  display: flex;
  justify-content: center;
}
.loader_modal .ant-table-content {
  overflow: auto;
}
@media (max-width: 450px) {
  .loader_modal .ant-table-content {
    font-size: 10px;
  }
}
.loader_modal .ant-modal-body {
  min-height: 300px;
  overflow: auto;
}
.loader_modal .textarea {
  background: white;
  color: black;
}
