.user_btn_group.vertical {
  display: flex;
  flex-direction: column;
}
.user_btn_group.title {
  margin-bottom: 50px;
}
.user_btn_group .btn {
  width: 100%;
  margin-right: 5px;
}
.user_btn_group .btn.hide {
  display: none;
}
.user_btn_group .btn .btn_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.user_btn_group .blue_btn:hover {
  opacity: 0.9;
}
