.leads-page .title {
  margin: 0;
}
.leads-page__config {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 8px;
  flex-wrap: wrap;
}
.lead_config_section {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
@media (max-width: 1100px) {
  .lead_config_section .lead_config-section {
    order: 2;
  }
}
@supports (-webkit-appearance:none) {
  .lead_config_section > *:not(:last-child) {
    margin-right: 15px;
  }
}
