.extra_footer {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .extra_footer > *:not(:last-child) {
    margin-right: 10px;
  }
}
.extra_footer button {
  cursor: pointer;
}
