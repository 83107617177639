@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .script_config_btn {
    margin-bottom: 10px;
  }
}
.script-page {
  padding-bottom: 50px;
}
.script-page .script-body {
  padding-bottom: 20px;
}
@media (max-width: 700px) {
  .script-page .script-body {
    padding-bottom: 5px;
  }
}
@media (max-width: 700px) {
  .script-page .script-body textarea {
    font-size: 10px;
  }
}
.script-page .section .config_panel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 25px 0 25px 0;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .script-page .section .config_panel > *:not(:last-child) {
    margin-right: 10px;
  }
}
.script-page .section .content_panel {
  display: flex;
  padding-bottom: 15px;
  flex-wrap: wrap;
}
.script-page .objection_type {
  margin-bottom: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.script-page .objection_type .title {
  font-weight: bold;
  margin-right: 10px;
}
@media (max-width: 450px) {
  .script-page .objection_type .title {
    font-size: 10px;
    margin-right: 3px;
  }
}
.script-page .saveScript-section {
  border-top: 2px solid grey;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.script-page .script_config {
  margin-bottom: 15px;
}
.script-page .spinnerForm {
  position: relative;
  left: 40%;
  top: 50px;
}
.script-page .overed {
  transition: all 0.3s ease-out;
  transform: scale(1.11);
}
@media (max-width: 450px) {
  .script-page .script_title {
    font-size: 15px;
  }
}
@media (max-width: 450px) {
  .script-page .config_title {
    font-size: 14px;
  }
}
.script-page .load-script-btn {
  margin-bottom: 10px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  width: 100%;
  align-items: center;
}
.script_config-row {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .script_config-row > *:not(:last-child) {
    margin-right: 15px;
  }
}
.script_config-questions {
  margin-bottom: 15px;
}
.script_config-questions .questions-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.script_config-questions .questions-section .title {
  font-weight: normal;
}
@media (max-width: 450px) {
  .script_config-questions .questions-section .title {
    font-size: 15px;
  }
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .script_config-questions .questions-section > *:not(:last-child) {
    margin-right: 15px;
  }
  .script_config-questions .questions-section > * {
    margin-top: 10px;
  }
}
.script_config-questions .answers-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}
.script_config-questions .answers-section .title {
  font-weight: normal;
}
@media (max-width: 450px) {
  .script_config-questions .answers-section .title {
    font-size: 15px;
  }
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .script_config-questions .answers-section > *:not(:last-child) {
    margin-right: 15px;
  }
}
.script_config-questions h3 {
  min-width: 80px;
  margin: 0;
  font-weight: bold;
}
.tab-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-wrapper:hover .icons-wrapper {
  visibility: visible;
}
.icons-wrapper {
  margin-left: 25px;
  visibility: hidden;
}
@media (max-width: 600px) {
  .icons-wrapper {
    visibility: visible;
  }
}
.icons-wrapper__edit-icon {
  right: -3px;
  top: 35%;
  color: red;
}
.icons-wrapper__edit-icon:hover {
  transform: scale(1.25);
}
.icons_wrapper__delete-icon {
  right: 15px;
  color: green;
  top: 35%;
}
.icons_wrapper__delete-icon:hover {
  transform: scale(1.25);
}
