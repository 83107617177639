.tag {
  flex-direction: row;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  margin: 5px 5px 5px 5px;
  align-items: center;
  padding: 8px 4px 8px 22px;
}
@media (max-width: 450px) {
  .tag {
    max-width: 185px;
    font-size: 9px;
  }
}
@media (max-width: 340px) {
  .tag {
    max-width: 165px;
    font-size: 8px;
  }
}
.tag .text {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag .icon__group {
  visibility: hidden;
  display: flex;
  margin-left: 3px;
}
.tag .icon__group .icon {
  font-size: 14px;
  padding: 3px;
}
.tag .icon__group .icon.edit {
  color: darkgreen;
}
.tag .icon__group .icon.delete {
  color: red;
}
.tag .icon__group .icon:hover {
  transform: scale(1.1);
}
.tag:hover {
  transform: scale(1.05);
}
.tag:hover .icon__group {
  visibility: visible;
}
.tag.active {
  background: #1890ff;
  color: white;
}
