.timeline_item {
  margin-bottom: 20px;
}
.timeline_item:hover {
  color: black;
  cursor: pointer;
  transform: scale(1.03);
}
.timeline_item.error {
  color: #ff4d4f;
}
