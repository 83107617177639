.admin-page .timezone-panel {
  padding: 30px 12px 20px 12px;
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
}
.admin-page .timezone-panel .select {
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 450px) {
  .admin-page .timezone-panel .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
}
.admin-page .timezone-panel .title {
  font-weight: bold;
  margin-top: 4px;
}
@media (max-width: 450px) {
  .admin-page .timezone-panel .title {
    margin-bottom: 7px;
  }
}
