.admin-page .user-config {
  padding: 30px 12px 20px 12px;
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .admin-page .user-config > *:not(:last-child) {
    margin-right: 10px;
  }
  .admin-page .user-config .btn_group > * {
    margin-top: 10px;
  }
}
