.leadsTable {
  padding-bottom: 50px;
}
.leadsTable thead[class*="ant-table-thead"] th:hover {
  background: #f0f0f0;
}
.leadsTable .activeFilter {
  color: #40a9ff;
}
.leadsTable .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}
.leadsTable .ant-table-content {
  border-right: 1px solid #f0f0f0;
}
.leadsTable .name_column {
  min-width: 200px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .name_column {
    max-width: 145px;
  }
}
.leadsTable .email_column {
  min-width: 200px;
  width: 200px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .email_column {
    max-width: 145px;
  }
}
.leadsTable .leadInfo_column {
  min-width: 250px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .leadInfo_column {
    max-width: 145px;
  }
}
.leadsTable .action_column {
  min-width: 150px;
}
.leadsTable .users_column {
  min-width: 200px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .users_column {
    max-width: 145px;
  }
}
.leadsTable .scripts_column {
  min-width: 200px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .scripts_column {
    max-width: 145px;
  }
}
.leadsTable .status_column {
  min-width: 120px;
  width: 200px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .status_column {
    max-width: 145px;
  }
}
.leadsTable .funnel_column {
  min-width: 180px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .funnel_column {
    max-width: 145px;
  }
}
.leadsTable .phone_column {
  min-width: 160px;
  max-width: 400px;
}
.leadsTable .status_column {
  min-width: 160px;
  max-width: 400px;
}
.leadsTable .funnelStage_column {
  min-width: 180px;
  max-width: 400px;
}
@media only screen and (max-width: 599px) {
  .leadsTable .funnelStage_column {
    max-width: 145px;
  }
}
