.admin-page .config__attributes {
  padding: 30px 12px 20px 12px;
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
}
.admin-page .config__attributes .attributes-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
