.login_header {
  z-index: 100;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  background: #C3F3FF;
  right: 0;
  border-bottom: 2px solid lightgrey;
}
@media (max-width: 600px) {
  .login_header {
    height: 60px;
  }
}
