.user_dropdown {
  width: 300px;
  outline: none;
  outline-offset: 0;
}
.user_dropdown .ant-select-selector {
  max-height: 120px;
  overflow: auto;
}
@media (max-width: 900px) {
  .user_dropdown {
    max-width: 300px;
  }
}
@media (max-width: 768px) {
  .user_dropdown {
    max-width: 250px;
  }
}
@media (max-width: 600px) {
  .user_dropdown {
    max-width: 180px;
    font-size: 12px;
  }
}
@media (max-width: 385px) {
  .user_dropdown {
    max-width: 160px;
    font-size: 12px;
  }
}
.user_dropdown.full_width {
  width: 100%;
  max-width: 100%;
}
.user_dropdown:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
.user_dropdown.basic {
  max-width: 100%;
}
.user_dropdown.error.ant-select .ant-select-selector {
  border: 1px solid #ff4d4f;
}
.user_dropdown.error.ant-select:hover .ant-select-selector {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
