.employeeTableCharts {
  margin-bottom: 20px;
}
.employeeTableCharts .dateTrunc_column {
  min-width: 100px;
}
.employeeTableCharts .dials_column {
  min-width: 150px;
}
.employeeTableCharts .conversations_column {
  min-width: 150px;
}
.employeeTableCharts .pitches_column {
  min-width: 150px;
}
.employeeTableCharts .sales_column {
  min-width: 150px;
}
.employeeTableCharts .salesValue_column {
  min-width: 160px;
}
.employeeTableCharts .dateTrunc_column {
  width: 100px;
}
.employeeTableCharts .cash_column {
  min-width: 120px;
}
.employeeTableCharts .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 1px !important;
  height: 16px;
}
.employeeTableCharts .ant-progress-bg {
  height: 16px !important;
  border-radius: 1px !important;
}
.employeeTableCharts .ant-progress-text {
  position: relative;
  left: -10px;
  /*  top: 3px;*/
}
.employeeTableCharts .ant-progress-outer {
  position: relative;
  left: -5px;
}
.employeeTableCharts .ant-table-row.ant-table-row-level-0.metric_row {
  border-bottom: none !important;
  height: 25px !important;
}
.employeeTableCharts .ant-table-row.ant-table-row-level-0.metric_row .ant-table-cell {
  border-bottom: none;
}
