.admin-page__title {
  margin-top: 6px;
}
.admin-page__setting {
  display: flex;
  flex-direction: column;
  padding: 0 10px 35px 10px;
}
@media (max-width: 450px) {
  .admin-page__setting {
    padding: 0 3px 25px 3px;
  }
}
.admin-page .block__title {
  font-weight: bold;
}
.admin-page .panel {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .admin-page .panel > *:not(:last-child) {
    margin-right: 10px;
  }
}
.admin-page .panel .panel_row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.admin-page__tabs {
  overflow: auto;
}
.admin-page__tabs .ant-tabs-content {
  padding: 10px 0 10px 0;
}
.admin-page .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  border-radius: 3px;
  margin-right: 8px;
}
@media (max-width: 450px) {
  .admin-page .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    padding: 5px 9px 5px 9px;
  }
}
