.staff_detail {
  padding-top: 10px;
  padding-bottom: 30px;
}
.staff_detail .block_spinner {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staff_detail .config_panel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.staff_detail .config_panel .elements_wrapper {
  gap: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  align-items: flex-start;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .staff_detail .config_panel .elements_wrapper > * {
    margin-right: 10px;
    margin-top: 10px;
  }
}
@media (max-width: 450px) {
  .staff_detail .config_panel .elements_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .staff_detail .config_panel .elements_wrapper .ant-picker.ant-picker-range {
    max-width: 100%;
  }
}
@media (max-width: 450px) {
  .staff_detail .config_panel .dropdown_wrapper {
    width: 100%;
  }
  .staff_detail .config_panel .dropdown_wrapper .user_dropdown {
    max-width: 100%;
  }
}
.staff_detail .config_panel .dropdown_wrapper-error {
  position: relative;
  display: block;
  color: #ff4d4f;
  font-size: 14px;
  margin-left: 5px;
}
.staff_detail .content .reason_stat {
  margin-bottom: 10px;
}
.staff_detail .content .reason_stat .filter {
  min-width: 200px;
  max-width: 450px;
}
.staff_detail .content .reason_stat .filter .ant-select-selector {
  max-height: 120px;
  overflow: auto;
}
@media (max-width: 768px) {
  .staff_detail .content .reason_stat .filter {
    min-width: 120px;
    max-width: 120px;
  }
}
@media (max-width: 360px) {
  .staff_detail .content .reason_stat .filter {
    min-width: 110px;
    max-width: 110px;
  }
}
.staff_detail .content .timeline_stat .filter {
  min-width: 200px;
  max-width: 300px;
}
.staff_detail .content .timeline_stat .filter .ant-select-selector {
  max-height: 120px;
  overflow: auto;
}
@media (max-width: 768px) {
  .staff_detail .content .timeline_stat .filter {
    min-width: 120px;
    max-width: 120px;
  }
}
@media (max-width: 360px) {
  .staff_detail .content .timeline_stat .filter {
    min-width: 110px;
    max-width: 110px;
  }
}
@media (max-width: 360px) {
  .staff_detail .content .cards .ant-card-head {
    padding: 0 5px 0 5px;
  }
}
.staff_detail .content .cards .ant-card-head-title {
  font-weight: bold;
}
@media (max-width: 450px) {
  .staff_detail .content .cards .ant-card-head-title {
    font-size: 14px;
  }
}
.staff_detail .content .cards .card {
  min-height: 140px;
}
@media (max-width: 1024px) {
  .staff_detail .content .cards .card {
    width: 50%;
    padding: 10px;
    min-height: 100px;
  }
}
.staff_detail .content .cards .card .name {
  padding: 3px;
  color: darkgray;
  font-weight: bold;
}
@media (max-width: 450px) {
  .staff_detail .content .cards .card .name {
    font-size: 12px;
  }
}
.staff_detail .content .cards .card .text {
  padding: 3px;
  color: darkgray;
}
.staff_detail .content .cards .card .count {
  padding: 3px;
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 450px) {
  .staff_detail .content .cards .card .count {
    font-size: 16px;
  }
}
.activity_table .type_column {
  min-width: 160px;
}
.activity_table .info_column {
  min-width: 160px;
}
.activity_table .date_column {
  min-width: 180px;
  max-width: 180px;
}
.xray.datepicker {
  width: 400px;
  outline: none;
  outline-offset: 0;
}
@media (max-width: 900px) {
  .xray.datepicker {
    max-width: 300px;
  }
}
@media (max-width: 768px) {
  .xray.datepicker {
    max-width: 250px;
  }
}
@media (max-width: 600px) {
  .xray.datepicker {
    max-width: 180px;
    font-size: 12px;
  }
}
@media (max-width: 385px) {
  .xray.datepicker {
    max-width: 160px;
    font-size: 12px;
  }
}
