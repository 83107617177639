.dashboard__item {
  margin-top: 16px;
  opacity: 0.9;
}
.dashboard__item .icon:hover {
  transform: scale(1.08);
}
.dashboard__item .icon.delete:hover {
  color: red;
}
.dashboard__item .icon.edit:hover {
  color: green;
}
@media (min-width: 1080px) and (max-width: 1210px) {
  .dashboard__item .ant-card-meta-title {
    font-size: 12px;
  }
}
@media (min-width: 850px) and (max-width: 1080px) {
  .dashboard__item .ant-card-meta-title {
    font-size: 10px;
  }
}
@media (max-width: 850px) {
  .dashboard__item .ant-card-meta-title {
    font-size: 10px;
  }
  .dashboard__item .ant-card-meta-avatar {
    padding-right: 5px;
  }
  .dashboard__item .ant-card-body {
    padding: 12px;
  }
}
@media (max-width: 390px) {
  .dashboard__item .ant-card-meta-title {
    font-size: 8px;
  }
}
.dashboard__item:hover {
  opacity: 1;
  cursor: pointer;
  transition-duration: 1s;
  transform: scale(1.01);
  background: white;
  z-index: 30;
}
