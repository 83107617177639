.dots_component .circle_list {
  display: flex;
  justify-content: center;
}
.dots_component .circle_list > *:not(:last-child) {
  margin-right: 20px;
}
.dots_component h3 {
  font-size: 27px;
  margin-bottom: 35px;
  text-align: center;
  color: white;
}
@media (max-width: 450px) {
  .dots_component h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
.dots_component .circle_item {
  border-radius: 50%;
  position: relative;
  width: 37px;
  height: 37px;
  border: 2px solid black;
  cursor: pointer;
}
@media (max-width: 450px) {
  .dots_component .circle_item {
    width: 20px;
    height: 20px;
  }
}
.dots_component .circle_item:hover {
  opacity: 1;
  transform: scale(1.02);
}
.dots_component .circle_item.red {
  background: #e51c1c;
}
.dots_component .circle_item.green {
  background: #46e397;
}
.dots_component .circle_item.grey {
  background: #828282;
}
.dots_component .circle_item.yellow {
  background: #FBDA5E;
}
.dots_component .activeDotIcon {
  color: black;
  font-size: 34px;
  position: absolute;
  left: 0;
  top: -1.5px;
}
@media (max-width: 450px) {
  .dots_component .activeDotIcon {
    font-size: 16px;
    left: 0;
  }
}
