.block_title {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 599px) {
  .block_title {
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .block_title {
    font-size: 13px;
  }
}
@media only screen and (min-width: 900px) {
  .block_title {
    font-size: 15px;
  }
}
.block_title.left {
  text-align: left;
}
.block_title .bold {
  font-weight: bold;
}
