.admin-page .config-dashboards {
  padding: 30px 12px 20px 12px;
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
}
.admin-page .config-dashboards .carousel_dots {
  margin-top: 10px;
}
.admin-page .config-dashboards .react-multi-carousel-list {
  padding-bottom: 15px;
}
.admin-page .config-dashboards .dashboard_row {
  padding: 10px 10px 25px 10px;
  display: flex;
  flex-wrap: wrap;
}
.admin-page .config-dashboards .dashboard__container {
  flex: 1;
  display: flex;
  overflow: auto;
  padding: 10px;
}
.admin-page .config-dashboards .carousel_container .carousel_item {
  padding-right: 15px;
}
.admin-page .config-dashboards .carousel_container .react-multi-carousel-track {
  padding: 10px;
}
.admin-page .config-dashboards .carousel_container .react-multiple-carousel__arrow {
  min-width: 35px;
  min-height: 25px;
}
.admin-page .config-dashboards .carousel_container .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 0;
}
.admin-page .config-dashboards .carousel_container .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 0;
}
