.date_filter_container {
  padding: 20px 10px 15px 10px;
}
.date_filter_container .date_filter_btn_container {
  display: flex;
  justify-content: center;
}
.date_filter_container .date_filter_btn {
  width: 120px;
  margin-top: 10px;
}
.date_filter_container .date_filter_btn.reset {
  background: #ff4d4f;
  color: white;
}
