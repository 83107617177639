.admin-page .company-config {
  display: flex;
  align-items: center;
  margin: 0 10px 40px 0;
  flex-wrap: wrap;
  gap: 10px;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .admin-page .company-config > *:not(:last-child) {
    margin-right: 10px;
  }
  @media (max-width: 1256px) {
    .admin-page .company-config .company_dropdown {
      margin-bottom: 10px;
    }
  }
}
