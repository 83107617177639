.stats-page {
  padding-top: 10px;
  background: #2fd1f7;
  height: 100%;
  display: flex;
}
.stats-page .btn {
  width: 100%;
}
.stats-page .title {
  color: white;
  text-align: center;
}
.stats-page .stats_form {
  min-width: 600px;
}
@media (max-width: 900px) {
  .stats-page .stats_form {
    min-width: 500px;
  }
}
@media (max-width: 750px) {
  .stats-page .stats_form {
    min-width: 420px;
  }
}
@media (max-width: 600px) {
  .stats-page .stats_form {
    min-width: 100%;
  }
}
@media (max-width: 450px) {
  .stats-page .stats_form .ant-form-item {
    margin-bottom: 15px;
  }
}
.stats-page .stats_form .ant-form-item-label {
  text-align: left;
}
.stats-page .stats_form .ant-form-item-label label {
  font-weight: bold;
}
.stats-page .ant-list-bordered {
  border-radius: 10px;
  background: #fd5c2d;
}
.stats-page .ant-list-bordered .ant-list-item:hover {
  opacity: 0.9;
}
.stats-page .ant-list-bordered .title {
  color: black;
  font-weight: bold;
}
.stats-page .ant-list-bordered .training_link {
  color: white;
}
.stats-page .ant-list-bordered .training_link:hover {
  transform: scale(1.02);
}
