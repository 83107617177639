.dashboardsCard_section {
  display: flex;
  flex-wrap: wrap;
}
@supports (-webkit-appearance:none) {
  .dashboardsCard_section > *:not(:last-child) {
    margin-right: 6px;
  }
  .dashboardsCard_section > * {
    margin-bottom: 6px;
  }
}
.dashboardsCard_section .dashboard_item {
  border: 1px solid #f0f0f0;
  flex-grow: 1;
  white-space: nowrap;
  max-width: 300px;
  padding: 12px 10px;
}
@media (max-width: 1306px) {
  .dashboardsCard_section .dashboard_item {
    max-width: 200px;
  }
}
@media (max-width: 450px) {
  .dashboardsCard_section .dashboard_item {
    min-width: 100px;
    max-width: 100%;
  }
}
.dashboardsCard_section .dashboard_item .ant-card-body {
  padding: 6px 8px;
}
.dashboardsCard_section .dashboard_item .title {
  text-align: center;
  font-size: 12px;
  margin-bottom: 5px;
}
.dashboardsCard_section .dashboard_item .value {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.dashboardsCard_section .dashboard_item .stat {
  text-align: center;
  font-size: 15px;
}
.dashboardsCard_section .dashboard_item:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: box-shadow 0.3s, border-color 0.3s;
}
.dashboardsCard_section .dashboard_item .arrow {
  margin-right: 5px;
  font-size: 12px;
}
.dashboardsCard_section .dashboard_item .increase {
  color: green;
}
.dashboardsCard_section .dashboard_item .increase.red {
  color: red;
}
.dashboardsCard_section .dashboard_item .decrease {
  color: red;
}
.dashboardsCard_section .dashboard_item .decrease.green {
  color: green;
}
