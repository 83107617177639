.consultant-page .row {
  width: 100%;
}
.consultant-page .actions {
  display: flex;
}
.consultant-page .actions > *:not(:last-child) {
  margin-right: 10px;
}
.consultant-page .config_section {
  display: flex;
  gap: 15px;
  align-items: center;
  padding-top: 5px;
  justify-content: space-between;
}
.consultant-page .config_section .title {
  margin: 0;
}
.consultant-page .action_link {
  font-weight: bold;
  color: black;
}
.consultant-page .select_container {
  display: flex;
  align-items: center;
}
.consultant-page .select_container p {
  margin: 0 10px 0 0;
  font-weight: bold;
}
.consultant-page .table_view {
  margin-top: 10px;
}
.consultant-page .ant-table-selection-column {
  width: 30px;
}
.consultant-page .dropdown-text p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.consultant-page .ant-dropdown-link {
  display: inline-block;
  font-size: 13px;
}
@media (max-width: 450px) {
  .consultant-page .ant-dropdown-link {
    max-width: 300px;
  }
}
.consultant-page .ant-dropdown-link:hover {
  transform: scale(1.05);
}
.consultant-page .consultant_table .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}
.consultant-page .consultant_table .company-column {
  min-width: 200px;
  max-width: 200px;
}
.consultant-page .consultant_table .email-column {
  min-width: 200px;
  max-width: 200px;
}
.consultant-page .consultant_table .name-column {
  min-width: 200px;
  max-width: 200px;
}
.consultant-page .consultant_table .action_column {
  width: 110px;
}
.overlay_menu {
  max-height: 130px;
  overflow: auto;
}
