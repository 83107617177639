.btn.hide {
  display: none;
}
.btn_group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .btn_group > *:not(:last-child) {
    margin-right: 10px;
  }
}
.btn_group.vertical {
  display: flex;
  flex-direction: column;
}
.btn_group.title {
  margin-bottom: 50px;
}
