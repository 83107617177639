.breath_bar_wrapper {
  margin: 0 auto;
  width: 500px;
}
@media (max-width: 900px) {
  .breath_bar_wrapper {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .breath_bar_wrapper {
    width: 300px;
  }
}
.progress {
  display: flex;
  overflow: hidden;
  border-radius: 50px;
  background-color: #ecf0f1;
}
.progress-bar {
  overflow: hidden;
  text-align: center;
  border-radius: inherit;
  background-color: #fd5c2d;
}
.progress-bar-animated {
  position: relative;
  animation-name: widthanimate;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
@keyframes widthanimate {
  from {
    width: 0%;
    background-color: #193E82;
  }
  to {
    width: 100%;
    background-color: #73CCEE;
  }
}
@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 0.1;
  }
}
.breath_message {
  font-weight: bold;
  font-size: 20px;
}
