.login_footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 70px;
  background: #C3F3FF;
  right: 0;
  border-top: 2px solid lightgrey;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 120px;
}
.login_footer .report_issue {
  width: 180px;
}
.login_footer .report_issue:hover {
  transform: scale(1.05);
}
@media (max-width: 940px) {
  .login_footer {
    justify-content: center;
    padding-right: 0;
  }
}
@media (max-width: 600px) {
  .login_footer {
    height: 60px;
  }
}
