.reset_wrapper {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  padding-bottom: 70px;
}
.reset_wrapper .reset__form_section {
  display: block;
  width: 75%;
}
.reset_wrapper .reset__form_section .reset_preview_name {
  text-align: center;
  font-weight: bold;
  font-size: 70px;
  letter-spacing: 6px;
}
.reset_wrapper .reset__form_section .reset_message {
  text-align: center;
  letter-spacing: 2px;
  font-size: 32px;
}
.reset_wrapper .reset__form_section .reset_message:hover {
  opacity: 0.9;
}
.reset_wrapper .reset__form_section .reset-form {
  width: 550px;
  margin: 0 auto;
}
.reset_wrapper .reset__form_section .btn {
  width: 100%;
}
.reset_wrapper .reset__form_section .submit_btn {
  margin-bottom: 10px;
}
.reset_wrapper .reset_img_section {
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;
}
.reset_wrapper .reset_img_section .reset_img {
  max-width: 600px;
  height: 300px;
  text-align: left;
}
.reset_wrapper .reset_img_section .reset_img img {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}
@media (max-width: 940px) {
  .reset_wrapper {
    display: flex;
    flex-direction: column;
  }
  .reset_wrapper .reset__form_section .reset_preview_name {
    font-size: 35px;
  }
  .reset_wrapper .reset__form_section .reset-form {
    width: 260px;
  }
  .reset_wrapper .reset__form_section .reset_message {
    font-size: 25px;
  }
  .reset_wrapper .reset_img_section {
    order: -1;
    width: 150px;
    padding-top: 80px;
  }
  .reset_wrapper .reset_img_section .reset_img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 450px) {
  .reset_wrapper .reset__form_section .reset_preview_name {
    font-size: 25px;
  }
  .reset_wrapper .reset__form_section .reset-form {
    width: 260px;
  }
  .reset_wrapper .reset__form_section .reset_message {
    font-size: 14px;
  }
  .reset_wrapper .reset_img_section {
    order: -1;
    width: 130px;
    padding-top: 70px;
  }
}
@media (max-width: 350px) {
  .reset_wrapper .reset__form_section .reset_preview_name {
    font-size: 20px;
  }
  .reset_wrapper .reset__form_section .reset-form {
    width: 260px;
  }
  .reset_wrapper .reset__form_section .reset_message {
    font-size: 15px;
  }
  .reset_wrapper .reset_img_section {
    order: -1;
    width: 100px;
    padding-top: 70px;
  }
}
