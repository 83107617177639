.statsSubmission_page {
  padding-bottom: 50px;
}
.statsSubmission_page .stats_rangeConfig {
  margin-bottom: 5px;
}
@media (max-width: 1300px) {
  .statsSubmission_page .stats_rangeConfig .ant-page-header-heading-title {
    font-size: 16px;
  }
}
@media (max-width: 1300px) {
  .statsSubmission_page .stats_rangeConfig .ant-page-header-heading-left {
    margin-left: 10px;
  }
}
@media (max-width: 1300px) {
  .statsSubmission_page .stats_rangeConfig .ant-page-header-heading {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1000px) {
  .statsSubmission_page .stats_rangeConfig .ant-page-header-heading-extra {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .statsSubmission_page .stats_rangeConfig .ant-page-header-heading-extra > * {
    margin-left: 0;
  }
}
.statsSubmission_page .statsViewerTimezoneDropdown {
  max-width: 220px;
}
.statsSubmission_page .item_tag {
  cursor: pointer;
  margin-bottom: 3px;
}
.statsSubmission_page .ant-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.statsSubmission_page .stats_table .date_column {
  min-width: 155px;
}
.statsSubmission_page .stats_table .name_column {
  min-width: 160px;
}
.statsSubmission_page .stats_table .company_column {
  min-width: 160px;
}
.statsSubmission_page .stats_table .email_column {
  min-width: 160px;
}
.statsSubmission_page .stats_table .stat_column {
  min-width: 92px;
  width: 200px;
}
.statsSubmission_page .stats_table .conversation_column {
  min-width: 130px;
}
.statsSubmission_page .stats_table .timezone_column {
  min-width: 100px;
}
.statsSubmission_page .stats_table .guidance_column {
  min-width: 150px;
}
.stats_rangeConfig .title {
  margin-bottom: 0;
}
.stats_rangeConfig .ant-page-header {
  padding: 5px 0 0 0;
}
@media (max-width: 450px) {
  .stats_rangeConfig {
    margin-bottom: 10px;
  }
}
.stats_rangeConfig .ant-page-header-heading-extra {
  padding: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 450px) {
  .stats_rangeConfig .ant-page-header-heading-extra {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .stats_rangeConfig .ant-page-header-heading-extra .ant-page-header-heading {
    display: flex;
    justify-content: center;
  }
  .stats_rangeConfig .ant-page-header-heading-extra .select {
    width: 100%;
    max-width: 100%;
  }
  .stats_rangeConfig .ant-page-header-heading-extra .range_title {
    display: none;
  }
}
.stats_rangeConfig .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}
.ant-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
