.script_dropdown {
  width: 380px;
}
@media (max-width: 760px) {
  .script_dropdown {
    max-width: 190px;
  }
}
@media (max-width: 610px) {
  .script_dropdown {
    max-width: 180px;
    font-size: 12px;
  }
}
@media (max-width: 385px) {
  .script_dropdown {
    max-width: 160px;
  }
}
.script_dropdown.basic {
  max-width: 100%;
}
