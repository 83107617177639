.checkboxFilter_wrapper {
  padding: 16px 8px 8px 8px;
}
.checkboxFilter_wrapper .data_section {
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-height: 200px;
  white-space: nowrap;
  overflow-y: scroll;
}
.checkboxFilter_wrapper .data_section .ant-checkbox-wrapper {
  margin-left: 0;
}
.checkboxFilter_wrapper .button_wrapper {
  display: flex;
  padding: 16px 1px 3px 1px;
  justify-content: center;
}
.checkboxFilter_wrapper .button_wrapper .ok_btn {
  width: 70px;
}
.checkboxFilter_wrapper .button_wrapper .reset_btn {
  background: #ff4d4f;
  color: white;
  width: 70px;
}
