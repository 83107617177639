.home-page {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to right, #2fd1f7, #25c2e5);
  position: relative;
  overflow: auto;
}
.home-page__welcome-message {
  color: white;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 40px 0 40px 0;
  text-align: center;
}
.home-page .company_dropdown {
  width: 100%;
}
.home-page .script_dropdown {
  width: 100%;
}
.home-page__spinner {
  position: absolute;
  top: 30%;
}
.home-page__spinner .ant-spin-dot-item {
  background-color: white;
}
.home-page .spinner_home_dote {
  position: absolute;
  bottom: 30%;
}
.home-page .spinner_home_dote .ant-spin-dot-item {
  background-color: white;
}
@media (max-width: 800px) {
  .home-page .welcome_message {
    font-size: 25px;
  }
}
@media (max-width: 450px) {
  .home-page .welcome_message {
    font-size: 18px;
  }
}
.home-page .config-panel {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-bottom: 70px;
}
@media (max-width: 800px) {
  .home-page .config-panel {
    min-width: 250px;
  }
}
@media (max-width: 450px) {
  .home-page .config-panel {
    min-width: 220px;
    margin-bottom: 60px;
  }
}
@media (max-width: 340px) {
  .home-page .config-panel {
    min-width: 220px;
    margin-bottom: 20px;
  }
}
.home-page .config-panel .select_container {
  margin-bottom: 10px;
  margin-right: 0;
  position: relative;
}
.home-page .config-panel .select_container .title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  color: white;
}
@media (max-width: 450px) {
  .home-page .config-panel .select_container .title {
    font-size: 14px;
  }
}
.home-page .config-panel .start-btn {
  margin-top: 30px;
  min-width: 400px;
}
.home-page .config-panel .start-btn button {
  width: 100%;
}
.home-page .config-panel .start-btn a {
  margin-left: 20px;
  color: white;
}
.home-page .config-panel .start-btn .icon_phone {
  margin-right: 5px;
  font-size: 18px;
}
.home-page .config-panel .start-btn .icon_phone:hover {
  transform: scale(1.05);
}
@media (max-width: 800px) {
  .home-page .config-panel .start-btn {
    min-width: 100%;
  }
}
