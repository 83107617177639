.ant-layout-header.header {
  z-index: 1000;
}
.header.hidden {
  display: none;
  overflow: auto;
}
@media (max-width: 740px) {
  .header {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.header_settings .item.active {
  background: #f28a3c;
}
.header_settings .setting_icon:hover {
  transform: scale(1.06);
}
.header {
  position: sticky;
  top: 0;
  z-index: 400;
  background: linear-gradient(to right, #2fd1f7, #25c2e5);
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
}
.header .header_navigation_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header .header_navigation_btn .btn {
  font-weight: bold;
  margin-left: 40px;
  background: #2fd1f7;
  border: 2px solid white;
  border-radius: 30px;
  color: white;
  height: 37px;
  min-width: 160px;
  font-size: 14px;
}
.header .header_navigation_btn .active {
  background: linear-gradient(to left, #178199cf, #138fabcf);
}
@media (max-width: 740px) {
  .header .header_navigation_btn .btn {
    min-width: 100px;
    font-size: 12px;
    margin-left: 15px;
  }
}
@media (max-width: 420px) {
  .header .header_navigation_btn .btn {
    min-width: 98px;
    font-size: 10px;
    margin-left: 10px;
  }
}
.header .header_navigation_btn .btn:hover {
  transform: scale(1.01);
  cursor: pointer;
  opacity: 0.8;
}
.header .header_navigation_btn .logout_tab {
  margin-left: 100px;
}
.header .header_navigation_btn .logout_tab:hover {
  transform: scale(1.01);
}
.header .header_navigation_btn .user_avatar {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  cursor: pointer;
}
@media (max-width: 740px) {
  .header .header_navigation_btn .logout_tab {
    margin-left: 10px;
  }
  .header .header_navigation_btn .user_avatar {
    width: 38px;
    height: 38px;
  }
}
@media (max-width: 450px) {
  .header .header_navigation_btn .logout_tab {
    margin-left: 7px;
  }
  .header .header_navigation_btn .user_avatar {
    width: 35px;
    height: 35px;
  }
}
.header .drawer_btn {
  border: 2px solid white;
  background: #2fd1f7;
  color: white;
}
@media (max-width: 450px) {
  .header .drawer_btn {
    padding-right: 7px;
    padding-left: 7px;
  }
}
.header .drawer_btn:hover {
  transform: scale(1.03);
}
.side_icon {
  margin-right: 10px;
}
