.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 70px;
  background: linear-gradient(to right, #2fd1f7, #25c2e5);
  right: 0;
  border-top: 1px solid lightgrey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 120px;
  padding-left: 100px;
}
.footer > *:not(:last-child) {
  margin-right: 35px;
}
.footer .btn.report_issue {
  min-width: 180px;
  height: 37px;
  font-weight: bold;
  border: 2px solid white;
  background: transparent;
  border-radius: 16px;
  color: white;
  font-size: 14px;
}
.footer .btn.report_issue:hover {
  transform: scale(1.02);
}
@media (max-width: 800px) {
  .footer {
    gap: 15px;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
  }
  .footer .btn.report_issue {
    min-width: 120px;
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .footer .btn.report_issue {
    min-width: 98px;
    font-size: 10px;
    margin-left: 10px;
  }
}
