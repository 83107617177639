.multiselect {
  width: 100%;
}
.multiselect .ant-select-selector {
  max-height: 140px;
  overflow: auto;
}
.multiselect.basic {
  width: 200px;
}
