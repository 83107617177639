.text-editor-viewer {
  /*
    Styles for remove
   */
  /* Set content font-families */
}
.text-editor-viewer .ql-formats:hover .test {
  display: block;
}
.text-editor-viewer .ql-toolbar.ql-snow {
  display: flex;
}
.text-editor-viewer .ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
  pointer-events: auto!important;
}
.text-editor-viewer .ql-container.ql-disabled .ql-editor ul[data-unchecked] > li::before {
  pointer-events: auto!important;
}
.text-editor-viewer .ql-editor {
  -webkit-user-modify: read-only !important;
  -moz-user-modify: read-only !important;
  user-modify: read-only !important;
  max-height: 620px;
}
@media (max-height: 760px) {
  .text-editor-viewer .ql-editor {
    max-height: 400px;
  }
}
@media (min-height: 760px) and (max-height: 800px) {
  .text-editor-viewer .ql-editor {
    max-height: 500px;
  }
}
@media (min-height: 800px) and (max-height: 880px) {
  .text-editor-viewer .ql-editor {
    max-height: 530px;
  }
}
@media (min-height: 880px) and (max-height: 914px) {
  .text-editor-viewer .ql-editor {
    max-height: 600px;
  }
}
@media (min-height: 915px) and (max-height: 935px) {
  .text-editor-viewer .ql-editor {
    max-height: 620px;
  }
}
@media (min-height: 950px) and (max-height: 1000px) {
  .text-editor-viewer .ql-editor {
    max-height: 655px;
  }
}
@media (min-height: 1000px) {
  .text-editor-viewer .ql-editor {
    max-height: 700px;
  }
}
@media (min-height: 1300px) and (max-height: 1400px) {
  .text-editor-viewer .ql-editor {
    max-height: 1000px;
  }
}
.text-editor-viewer .ql-toolbar .ql-picker {
  cursor: pointer;
}
.text-editor-viewer .ql-toolbar .ql-fill {
  cursor: pointer;
}
.text-editor-viewer .ql-toolbar .ql-stroke {
  cursor: pointer;
}
.text-editor-viewer .ql-editor ul[data-checked="false"] > li::before {
  content: url("../../../assets/checkbox-off.svg");
  font-size: 1.5rem;
  position: relative;
  width: 1em;
  height: 1em;
}
.text-editor-viewer .ql-editor ul[data-checked="true"] li::before {
  content: url("../../../assets/checkbox-on.svg");
  font-size: 1.5rem;
  position: relative;
  width: 1em;
  height: 1em;
}
.text-editor-viewer .ql-container {
  font-size: 16px;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size {
  width: 75px;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  content: '15px';
  font-size: 15px !important;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
  font-size: 16px !important;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
  content: '17px';
  font-size: 17px !important;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
  font-size: 18px !important;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px';
  font-size: 20px !important;
}
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.text-editor-viewer .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-item {
  font-size: 0;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-item:before {
  content: attr(data-value) !important;
  font-size: 14px;
}
.text-editor-viewer .ql-picker.ql-font .ql-active:before {
  content: attr(data-value) !important;
  font-size: 14px;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.text-editor-viewer .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.text-editor-viewer .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.text-editor-viewer .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.text-editor-viewer .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}
.text-editor-viewer .ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.text-editor-viewer .ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  content: "Rubik" !important;
}
.text-editor-viewer .ql-font-Roboto {
  font-family: "Roboto";
}
.text-editor-viewer .ql-font-Raleway {
  font-family: "Raleway";
}
.text-editor-viewer .ql-font-Montserrat {
  font-family: "Montserrat";
}
.text-editor-viewer .ql-font-Lato {
  font-family: "Lato";
}
.text-editor-viewer .ql-font-Rubik {
  font-family: "Rubik";
}
