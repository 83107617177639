.meetingPlan-page {
  height: 100%;
  position: relative;
  padding-bottom: 80px;
}
.meetingPlan-page .popup_block {
  position: absolute;
  left: 40%;
}
@media (max-width: 1400px) {
  .meetingPlan-page .popup_block {
    left: 45%;
  }
}
@media (max-width: 1000px) {
  .meetingPlan-page .popup_block {
    left: 55%;
  }
}
.meetingPlan-page .meetingPlan_form_item .ant-select-selector {
  max-height: 100px;
  overflow: auto;
}
.meetingPlan-page #meetingConfig_form {
  display: flex;
}
.meetingPlan-page #meetingConfig_form > *:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 800px) {
  .meetingPlan-page #meetingConfig_form {
    display: block;
  }
}
.meetingPlan-page .title {
  margin-top: 10px;
}
@media (max-width: 450px) {
  .meetingPlan-page .ant-col.ant-form-item-label {
    padding: 0;
  }
}
.meetingPlan-page .ant-form-item-label {
  padding-bottom: 3px;
}
.meetingPlan-page .meetingPlan_form_item {
  width: 300px;
}
@media (max-width: 450px) {
  .meetingPlan-page .meetingPlan_form_item {
    width: 250px;
  }
}
.meetingPlan-page .title {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}
.meetingPlan-page .bottom_btn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}
.meetingPlan-page .bottom_btn button:first-child {
  margin-right: 10px;
}
.meetingPlan-page .plan_notes {
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
}
.meetingPlan-page .plan_notes > *:not(:last-child) {
  margin-bottom: 15px;
}
.guidance_modal .rate_wrapper {
  padding: 0 0 0 20px;
  margin-bottom: 15px;
}
.guidance_modal .rate_wrapper .title {
  margin-right: 12px;
}
.ant-modal.guidance_modal {
  width: 45% !important;
}
@media (max-width: 450px) {
  .ant-modal.guidance_modal {
    width: 100% !important;
  }
}
.ant-modal.guidance_modal .ant-form-item-required span {
  display: none;
}
.ant-modal.guidance_modal .ant-form-item-required:after {
  display: none;
}
