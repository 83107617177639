.pieCharts {
  border: 1px solid #f0f0f0;
  padding: 7px;
  margin-bottom: 30px;
}
.pieCharts .dateRange_title {
  font-weight: bold;
  font-size: 12px;
}
.pieChart {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #f0f0f0;
  padding: 10px;
}
#noDataRight {
  font-weight: bold;
  display: none;
}
#noDataLeft {
  font-weight: bold;
  display: none;
}
/*
.pies_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;


  @media (max-width: 1100px) {
    flex-direction: column;
  }

  .left_pie {
    padding: 10px;
    border: 1px solid #f0f0f0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    height: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
 !*   display: flex;
    justify-content: center;*!
  }

  .right_pie {
    padding: 10px;
    border: 1px solid #f0f0f0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 1px;
    height: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
 !*   display: flex;
    justify-content: center;*!
  }

}*/
