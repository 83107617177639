.guidance_table {
  margin-top: 30px;
  margin-bottom: 20px;
}
.guidance_table th.ant-table-cell.prevGuidance_column {
  text-align: center!important;
}
.guidance_table .ant-table-content {
  max-height: 550px;
  overflow: auto;
}
.guidance_table .table .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}
.guidance_table .table .teamMember_column {
  width: 250px;
}
@media (max-width: 450px) {
  .guidance_table .table .teamMember_column {
    width: 200px;
  }
}
.guidance_table .table .level_column {
  width: 100px;
}
@media (max-width: 1000px) {
  .guidance_table .table .prevGuidance_column {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .guidance_table .table .prevGuidance_column {
    width: 300px;
  }
}
.guidance_table .table .guidance_column {
  width: 95px;
}
.guidance_table .table .guidance_actions {
  width: 90px;
}
