.ant-picker-dropdown {
  /*  z-index: 999!important;*/
}
@media (max-width: 450px) {
  .ant-picker-dropdown {
    font-size: 12px;
  }
}
.ant-picker-input input {
  font-size: 14px;
}
@media (max-width: 450px) {
  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .ant-picker-ranges {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 450px) {
  .ant-picker-header-view:last-child {
    display: none;
  }
}
