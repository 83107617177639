.wrapper {
  display: flex;
  justify-content: center;
}
.wrapper .forgot_link {
  font-size: 23px;
  color: black;
  font-weight: bold;
}
.reset_confirm_form {
  padding: 100px 0;
  margin: 0 auto;
  min-width: 700px;
}
