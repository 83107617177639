.stats_schedule__wrapper {
  padding: 30px 12px 30px 12px;
  border: 1px solid #f0f0f0;
}
.stats_schedule__wrapper .selectUser_wrapper {
  margin-bottom: 30px;
}
.stats_schedule__wrapper .label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.stats_schedule__wrapper .required-mark {
  position: static!important;
  display: flex;
}
.stats_schedule__wrapper .required-mark p {
  color: black;
}
.stats_schedule__wrapper .multiselect {
  margin-top: 8px;
}
.stats_schedule__wrapper .user_dropdown {
  margin-top: 8px;
}
.stats_schedule__wrapper .schedule_config {
  position: relative;
  padding-top: 10px;
}
.stats_schedule__wrapper .schedule_config .daysRow_title {
  text-align: left;
}
.stats_schedule__wrapper .schedule_config .days_row {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 20px;
}
.stats_schedule__wrapper .schedule_config .days_row .checkbox {
  font-weight: 300;
}
.stats_schedule__wrapper .schedule_config .config_row {
  margin-bottom: 30px;
}
.stats_schedule__wrapper .schedule_config .config_row .select {
  width: 100%;
  max-width: 100%;
}
.stats_schedule__wrapper .schedule_config .config_row .error-mark {
  color: #ff4d4f;
  position: absolute;
  left: 12px;
  bottom: -25px;
}
.stats_schedule__wrapper .schedule_config .config_row .required-mark {
  color: #ff4d4f;
  position: relative;
  right: 5px;
}
.stats_schedule__wrapper .schedule_config .config_row .required-mark p {
  margin-bottom: 8px;
}
.stats_schedule__wrapper .schedule_config .config_row .select:active,
.stats_schedule__wrapper .schedule_config .config_row :hover,
.stats_schedule__wrapper .schedule_config .config_row :focus {
  outline: 0;
  outline-offset: 0;
}
.stats_schedule__wrapper .schedule_config .config_row .error-field.ant-select .ant-select-selector {
  border: 1px solid #ff4d4f;
}
.stats_schedule__wrapper .schedule_config .config_row .error-field.ant-select:hover .ant-select-selector {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.stats_schedule__wrapper .schedule_config .ant-col {
  text-align: left;
}
