.employeeGuidanceTable {
  margin-bottom: 25px;
}
.employeeGuidanceTable .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}
.employeeGuidanceTable thead[class*="ant-table-thead"] th:hover {
  background: #f0f0f0;
}
.employeeGuidanceTable .ant-table-content {
  max-height: 450px;
  overflow-y: auto !important;
}
.employeeGuidanceTable .date_column {
  width: 120px;
}
.employeeGuidanceTable .dialsKpi_column {
  width: 110px;
}
.employeeGuidanceTable .pitchKpi_column {
  width: 110px;
}
.employeeGuidanceTable .conversationKPI_column {
  width: 110px;
}
.employeeGuidanceTable .salesKpi_column {
  width: 110px;
}
.employeeGuidanceTable .conversationsPerSale_column {
  width: 130px;
}
.employeeGuidanceTable .dialsPerSale_column {
  width: 110px;
}
.employeeGuidanceTable .dialsPerSale_column {
  width: 110px;
}
.employeeGuidanceTable .hoursDialedPerSale_column {
  width: 135px;
}
