.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  width: 100%;
  padding: 20px;
}
.login-page__content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}
.login-page__form {
  flex: 1;
  padding: 2rem;
  display: block;
  width: 75%;
}
.login-page__form .login_preview_name {
  text-align: center;
  font-weight: bold;
  font-size: 70px;
  letter-spacing: 6px;
}
.login-page__form .welcome_message {
  text-align: center;
  letter-spacing: 2px;
  font-size: 32px;
}
.login-page__form .welcome_message:hover {
  opacity: 0.9;
}
.login-page__form .login-form {
  width: 550px;
  margin: 0 auto;
}
.login-page__form .btn {
  width: 100%;
}
.login-page__form .btn.login {
  background: #eb692f;
  border-radius: 15px;
  color: white;
  font-size: 15px;
  height: 40px;
}
.login-page__form .btn.login:hover {
  border: none;
  opacity: 0.9;
}
.login-page__logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.login-page__logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.login-page .login_img_section {
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;
}
.login-page .login_img_section .login_img {
  max-width: 600px;
  height: 300px;
  text-align: left;
}
.login-page .login_img_section .login_img img {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}
.login-page .forgot_password-link {
  font-size: 15px;
  color: #eb692f;
  cursor: pointer;
}
.login-page .remember_me-checkbox {
  font-weight: bold;
  font-size: 15px;
}
.login-page .ant-checkbox-inner,
.login-page .ant-checkbox:hover .ant-checkbox-inner,
.login-page .ant-checkbox-input:focus + .ant-checkbox-inner {
  box-shadow: none;
  outline: 0;
  outline-offset: 0;
  border-color: #eb692f;
  color: black;
}
.login-page .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #eb692f;
  border-color: #eb692f;
}
@media (max-width: 940px) {
  .login-page__content {
    flex-direction: column-reverse;
  }
  .login-page__form {
    width: 100%;
    max-width: 550px;
  }
  .login-page__logo {
    margin-bottom: 2rem;
  }
  .login-page .login_preview_name {
    font-size: 35px;
  }
  .login-page .login-form {
    width: 260px;
  }
  .login-page .welcome_message {
    font-size: 25px;
  }
  .login_img_section {
    order: -1;
    width: 150px;
    padding-top: 80px;
  }
  .login_img_section .login_img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 450px) {
  .login-page__form .login_preview_name {
    font-size: 25px;
  }
  .login-page__form .login-form {
    width: 260px;
  }
  .login-page__form .welcome_message {
    font-size: 14px;
  }
  .login-page .login_img_section {
    order: -1;
    width: 130px;
    padding-top: 70px;
  }
}
@media (max-width: 350px) {
  .login-page__form .login_preview_name {
    font-size: 20px;
  }
  .login-page__form .login-form {
    width: 260px;
  }
  .login-page__form .welcome_message {
    font-size: 15px;
  }
  .login-page .login_img_section {
    order: -1;
    width: 100px;
    padding-top: 70px;
  }
}
