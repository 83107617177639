.company_dropdown {
  width: 400px;
}
@media (max-width: 900px) {
  .company_dropdown {
    max-width: 300px;
  }
}
@media (max-width: 750px) {
  .company_dropdown {
    max-width: 190px;
  }
}
@media (max-width: 600px) {
  .company_dropdown {
    max-width: 180px;
    font-size: 12px;
  }
}
@media (max-width: 385px) {
  .company_dropdown {
    max-width: 160px;
    font-size: 12px;
  }
}
.company_dropdown.admin_page {
  width: 300px;
}
.company_dropdown.basic {
  max-width: 100%;
}
