.react-tel-input .form-control {
  width: 100%;
  font-family: 'Segoe UI';
  border-radius: 0;
  height: 32px;
}
.react-tel-input .form-control::placeholder {
  opacity: 0.6;
  font-family: 'Segoe UI';
}
.success.form-control {
  border: 1px solid #1890ff;
}
.error.form-control {
  border: 1px solid red;
}
.default.form-control:hover {
  border: 1px solid #1890ff;
}
