.error-mark {
  color: #ff4d4f;
  position: absolute;
  left: 12px;
  bottom: -25px;
}
.required-mark {
  color: #ff4d4f;
  position: relative;
  right: 5px;
}
.select:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
.error-field.ant-select .ant-select-selector {
  border: 1px solid #ff4d4f;
}
.error-field.ant-select:hover .ant-select-selector {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
